import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get, compact } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildAuthorPath,
  buildPreProdContentAuthorPath,
  buildPreProdContentPath,
  buildRatedByPath,
  buildReviewPath,
  EditorialReviewEntities,
  EditorialReviewModel,
  FirstContentEntity,
} from 'client/data/models/editorial-review';
import { buildWhatsNewPath, VehicleComparisonsEntities, VehicleDetailsModel } from 'client/data/models/mmy-details';
import { PRE_PROD_ARTICLE_TYPE } from 'client/data/transforms/editorial-reviews/transform-make-model-year';
import { getRatingScale } from 'site-modules/shared/utils/editorial-helper';
import { CorePageParams, getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { separateFirstParagraph } from 'site-modules/shared/utils/string';
import { getTrimsComparisonUrl } from 'site-modules/shared/utils/core-link-constructor';
import { FirstContentArticles } from 'site-modules/shared/components/core-page/articles/first-content-articles/first-content-articles';
import { ReviewSection } from 'site-modules/shared/components/core-page/review-section/review-section';
import { ArticleAuthor } from 'site-modules/shared/components/preprod-articles/article-author';
import { PreProdContentArticles } from 'site-modules/shared/components/core-page/articles/pre-prod-content-articles/pre-prod-content-articles';
import {
  EditorialHighlightsListsUI,
  HIGHLIGHTS_NAME,
} from 'site-modules/shared/components/editorial-highlights-lists/editorial-highlights-lists';
import { MakeColumnNewsWidget } from 'site-modules/shared/components/column-news-widget/column-news-widget';
import { ReadyForEv, ReadyForEvTreehouse } from 'site-modules/shared/components/ready-for-ev/ready-for-ev';
import { TreehousePromotionCheck } from 'site-modules/shared/components/treehouse-promotion/treehouse-promotion-check';
import { RatingScorecard } from 'site-modules/shared/components/core-page/scorecard-editorial-review/rating-scorecard/rating-scorecard';
import { RatingScorecard as RatingScorecardV2 } from 'site-modules/shared/components/core-page/scorecard-editorial-review/rating-scorecard-v2/rating-scorecard';
import { ShoppingLink } from 'site-modules/shared/components/shopping-link/shopping-link';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import './scorecard-editorial-review.scss';

export function getEditorialHighlightsSectionsToRender({
  editorialReview,
  hasCoreHighlights,
  hasPreprodCta,
  isUsed,
  isPreprod,
  isCoreOnSrp,
}) {
  const hasProsCons = !!(get(editorialReview, 'cons.length') || get(editorialReview, 'pros.length'));
  const sectionsWithoutRatingAndRanking = hasProsCons ? [HIGHLIGHTS_NAME.WHATS_NEW] : [];

  let mobileSectionsToRender;
  let desktopSectionsToRender;

  switch (true) {
    case isCoreOnSrp: {
      break;
    }
    case isUsed: {
      mobileSectionsToRender = [HIGHLIGHTS_NAME.WHATS_NEW];
      break;
    }
    case isPreprod: {
      mobileSectionsToRender = hasCoreHighlights ? undefined : sectionsWithoutRatingAndRanking;
      desktopSectionsToRender = hasProsCons && hasPreprodCta ? undefined : sectionsWithoutRatingAndRanking;
      break;
    }
    // new
    default: {
      mobileSectionsToRender = hasCoreHighlights ? undefined : sectionsWithoutRatingAndRanking;
      desktopSectionsToRender = hasCoreHighlights ? undefined : sectionsWithoutRatingAndRanking;
      break;
    }
  }

  return {
    true: mobileSectionsToRender,
    false: desktopSectionsToRender,
  };
}

function ScorecardEditorialReviewBodyUI({
  params: { isElectric },
  editorialReview,
  reviewAuthor,
  reviewRatedBy,
  vehicle,
  isMobile,
  hasRouteYearParam,
  firstContent,
  firstContentAuthor,
  whatsNewContent,
  headingText,
  embeddedModules,
  isUsed,
  isPreprod,
  isCoreOnSrp,
  showScorecard,
  showHighlights,
  showSummary,
  hasPreprodCta,
  hasCoreHighlights,
  toggleButton,
  hasTrimsLink,
  isCore6112Enabled,
  isCore6231Test,
}) {
  const {
    year,
    model: { name: modelName, slug: modelSlug },
    make: { name: makeName, slug: makeSlug },
    edmundsTypeCategories,
  } = vehicle;
  const vehicleDisplayName = `${year} ${makeName} ${modelName}`;

  const hasHighlights =
    showHighlights &&
    (get(editorialReview, 'cons.length') ||
      get(editorialReview, 'pros.length') ||
      get(firstContent, 'bullets.value.length') ||
      get(whatsNewContent, 'length'));

  const editorialHighlights = !!hasHighlights && (
    <EditorialHighlightsListsUI
      editorialReview={editorialReview}
      firstContent={firstContent}
      whatsNewContent={whatsNewContent}
      className="mb-1_5 mb-md-2 mt-2"
      sectionsToRender={
        getEditorialHighlightsSectionsToRender({
          editorialReview,
          hasCoreHighlights,
          hasPreprodCta,
          isUsed,
          isPreprod,
          isCoreOnSrp,
        })[isMobile]
      }
      HeadingTag="h3"
      isPreprod={isPreprod}
    />
  );

  if (!get(editorialReview, 'reviewLink') && !get(firstContent, 'articles.length')) {
    return (
      <Fragment>
        {!!editorialHighlights && (
          <ReviewSection
            id="scorecard-editorial-intro"
            author={get(reviewAuthor, 'contentMetadata', {})}
            ratedBy={get(reviewRatedBy, 'contentMetadata', {})}
            headingClassName="heading-3 mb-0_5"
            heading={headingText || `${vehicleDisplayName} Review`}
            headingTag="h2"
          >
            {editorialHighlights}
          </ReviewSection>
        )}
      </Fragment>
    );
  }

  const {
    title,
    ratings,
    weRecommend,
    weRecommendTitle,
    goodCar,
    trimFeaturesTitle,
    trimFeaturesBodySnippet,
    trimFeaturesBody,
    reviewLink,
    summary,
    summaryTitle,
    needToKnow,
    needToKnowTitle,
    competitors,
    competitorsTitle,
    longTerm,
    longTermTitle,
  } = editorialReview;

  // Show FL/FD content if has no editorial review
  if (!reviewLink) {
    const {
      articles,
      hasPreProdContent,
      articlePublishDate,
      articleUpdateDate,
      dateModified,
      publishDate,
      subHeader,
      hasTeaser,
      hasMiscellaneous,
    } = firstContent;
    const updatedArticleDate = articleUpdateDate ? `(updated ${articleUpdateDate})` : '';
    const articleDate = `${articlePublishDate} ${updatedArticleDate}`.trim();

    // When both 'teaser' and 'miscellaneous' exist then don't render 'teaser' here
    const renderArticles =
      hasTeaser && hasMiscellaneous ? articles.filter(({ type }) => type !== PRE_PROD_ARTICLE_TYPE.TEASER) : articles;

    return (
      <Fragment>
        {hasPreProdContent ? (
          <Fragment>
            <h2 className={classnames('heading-3 mb-0', { main: isPreprod })}>
              {headingText || title || `${vehicleDisplayName} Review`}
            </h2>
            {isPreprod && subHeader && (
              // inline styles to avoid adding critical css key
              <div style={{ lineHeight: '1.5rem', fontSize: '1.25rem', marginTop: '0.25rem' }}>{subHeader}</div>
            )}
            <ArticleAuthor
              author={get(firstContentAuthor, 'contentMetadata', {})}
              date={articleDate}
              dateModified={dateModified}
              publishDate={publishDate}
              className="medium"
              lazyLoadImage
              showBioTooltip
              isDateTextBelow
            />
            {editorialHighlights}
            <PreProdContentArticles
              articles={renderArticles}
              titleClassName="heading-5"
              collapseBodyText={renderArticles.length > 2 && !isPreprod}
              hasPhotos={false}
              hasEdmundsSaysIcon
              incentivesAdProps={{ makeSlug, makeName, modelSlug, modelName, year, isPreprod }}
              showIncentiveAd={isMobile && isPreprod}
              hasTreehousePromo={isElectric && isPreprod}
              isMobile={isMobile}
            />
            <MakeColumnNewsWidget makeSlug={makeSlug} makeName={makeName} modelName={modelName} className="my-1_5" />
          </Fragment>
        ) : (
          <Fragment>
            {editorialHighlights}
            <FirstContentArticles
              articles={articles}
              isMobile={isMobile}
              isUsed={isUsed}
              noCollapse={isPreprod}
              suppressFirstMedia
            />
            <MakeColumnNewsWidget makeSlug={makeSlug} makeName={makeName} modelName={modelName} className="my-1_5" />
            <hr className="mt-1_5 mb-0" />
          </Fragment>
        )}
      </Fragment>
    );
  }

  const [first, rest] = separateFirstParagraph(summary);
  const rangeAndCostModule = get(embeddedModules, 'rangeAndCostModule');
  const otherYearsModule = get(embeddedModules, 'otherYearsModule');
  const researchLinksModule = get(embeddedModules, 'researchLinksModule');
  const incentiveAdModule = get(embeddedModules, 'incentiveAdModule');
  const buildPriceAdModule = get(embeddedModules, 'buildPriceAdModule');

  const hasRatingScorecard = !!(showScorecard && get(ratings, 'overall.rating'));
  const showTrimsLink = hasTrimsLink && !!trimFeaturesBody;

  const readyForEvPreprod = (
    <div className="preprod-ready-for-ev bg-cool-gray-90 px-1 py-1 rounded-12 mb-2">
      <ReadyForEv heading="Am I Ready for an EV?" className="bg-white rounded-12 px-1_5 py-1_5" />
    </div>
  );

  const ratingScorecardCtrl = (
    <RatingScorecard
      ratings={ratings}
      scale={getRatingScale(editorialReview)}
      edmundsTypeCategories={edmundsTypeCategories}
      modelName={modelName}
      embeddedModules={embeddedModules}
      isMobile={isMobile}
      showMobileSummary={isPreprod}
    />
  );

  return (
    <div className="scorecard-editorial-review" data-tracking-parent="edm-entry-editorial-review">
      <ReviewSection
        id="scorecard-editorial-intro"
        author={get(reviewAuthor, 'contentMetadata', {})}
        ratedBy={get(reviewRatedBy, 'contentMetadata', {})}
        headingClassName="heading-3 mb-0_5"
        heading={headingText || title || `${vehicleDisplayName} Review`}
        headingTag="h2"
      >
        {editorialHighlights}
      </ReviewSection>
      {showSummary && <ReviewSection body={first} heading={summaryTitle} />}
      {rangeAndCostModule}
      {incentiveAdModule}
      {otherYearsModule}
      <ReviewSection body={rest} />
      <ReviewSection body={needToKnow} heading={needToKnowTitle} />
      <ReviewSection body={competitors} heading={competitorsTitle} />
      <ReviewSection body={longTerm} heading={longTermTitle} />

      {isElectric && isPreprod && (
        <TreehousePromotionCheck fallback={readyForEvPreprod}>
          <div className="preprod-treehouse-promotion bg-cool-gray-90 px-1 py-1 rounded-12 mb-2">
            <ReadyForEvTreehouse
              className="bg-white rounded-12 px-1_5 py-1_5 mx-auto"
              heading="Am I Ready for an EV?"
              creativeId="treehouse-promotion"
            />
          </div>
        </TreehousePromotionCheck>
      )}

      {hasRatingScorecard && (
        <Fragment>
          {isCore6231Test ? (
            <Experiment name="core-6231-collapsed" showDefault>
              <Recipe name="ctrl" isDefault>
                {ratingScorecardCtrl}
              </Recipe>
              <Recipe name="chal">
                <RatingScorecardV2
                  ratings={ratings}
                  scale={getRatingScale(editorialReview)}
                  edmundsTypeCategories={edmundsTypeCategories}
                  modelName={modelName}
                  embeddedModules={embeddedModules}
                  isMobile={isMobile}
                  showMobileSummary={isPreprod}
                />
              </Recipe>
            </Experiment>
          ) : (
            ratingScorecardCtrl
          )}
        </Fragment>
      )}
      {buildPriceAdModule}
      <ReviewSection
        body={weRecommend}
        heading={weRecommendTitle || `Which ${modelName} does Edmunds recommend?`}
        bodyCreativeId="edm-we-recommend"
        headingTag="h2"
      />
      {showTrimsLink && (
        <div className="rounded-12 bg-cool-gray-90 px-1 py-1 mb-1_5">
          <ShoppingLink
            to={getTrimsComparisonUrl({ makeSlug, modelSlug, year })}
            label={`Compare ${year} ${makeName} ${modelName} trim levels`}
            description="Helpful trims summary and side-by-side comparison chart"
            descriptionClassName={classnames({ 'mt-0_75': isMobile })}
            iconClassName="icon-compare_cars"
            className="bg-white rounded-8 px-1 py-1 py-md-1_25 pl-md-1_5 pr-md-2"
            labelClassName="heading-5 mb-0_25"
            iconContainerClassName="mr-0_75 mr-md-1_5 size-20 text-white bg-blue-50"
            iconStyles={{ height: '48px', width: '48px' }}
            arrowClassName="icon-arrow-right3 size-16 text-primary-darker"
            isDescriptionBelow={isMobile}
            data-tracking-id="compare_trims"
            data-tracking-value="Compare trim levels"
          />
        </div>
      )}
      {!(showTrimsLink && isCore6112Enabled) && (
        <ReviewSection
          body={
            isPreprod || isCoreOnSrp
              ? compact([trimFeaturesBodySnippet, trimFeaturesBody]).join('\n')
              : trimFeaturesBodySnippet
          }
          collapsedBody={isPreprod || isCoreOnSrp ? null : trimFeaturesBody}
          id="scorecard-editorial-trims"
          heading={trimFeaturesTitle || `${hasRouteYearParam ? `${year} ` : ''}${makeName} ${modelName} models`}
          headingTag="h2"
          bodyClassName="trim-features mb-0_75"
          collapsedBodyClasName="trim-features"
          collapseTrackingId="view_trim_features"
          bodyCreativeId="edm-trim-features"
          afterBodyChildren={
            isCoreOnSrp ? (
              <Fragment>
                {!hasRatingScorecard && researchLinksModule}
                {toggleButton}
              </Fragment>
            ) : (
              <MakeColumnNewsWidget
                makeSlug={makeSlug}
                makeName={makeName}
                modelName={modelName}
                className="my-1_5"
                creativeId="news_module"
              />
            )
          }
        />
      )}
      <ReviewSection body={goodCar} heading={`Is the ${makeName} ${modelName} a good car?`} headingTag="h2" />
    </div>
  );
}

ScorecardEditorialReviewBodyUI.propTypes = {
  params: CorePageParams.isRequired,
  editorialReview: EditorialReviewEntities.EditorialReview,
  reviewAuthor: EditorialReviewEntities.Author,
  reviewRatedBy: EditorialReviewEntities.Author,
  firstContentAuthor: EditorialReviewEntities.Author,
  firstContent: FirstContentEntity,
  whatsNewContent: VehicleComparisonsEntities.WhatsNewContent,
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      name: PropTypes.string,
    }),
    model: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  embeddedModules: PropTypes.shape({
    pricingModule: PropTypes.node,
    rangeAndCostModule: PropTypes.node,
    otherYearsModule: PropTypes.node,
    researchLinksModule: PropTypes.node,
    incentiveAdModule: PropTypes.node,
    buildPriceAdModule: PropTypes.node,
  }),
  headingText: PropTypes.string,
  isMobile: PropTypes.bool,
  hasRouteYearParam: PropTypes.bool,
  isUsed: PropTypes.bool,
  isPreprod: PropTypes.bool,
  isCoreOnSrp: PropTypes.bool,
  showScorecard: PropTypes.bool,
  showSummary: PropTypes.bool,
  showHighlights: PropTypes.bool,
  hasPreprodCta: PropTypes.bool,
  hasCoreHighlights: PropTypes.bool,
  toggleButton: PropTypes.node,
  hasTrimsLink: PropTypes.bool,
  isCore6112Enabled: PropTypes.bool,
  isCore6231Test: PropTypes.bool,
};

ScorecardEditorialReviewBodyUI.defaultProps = {
  editorialReview: null,
  reviewAuthor: null,
  reviewRatedBy: null,
  firstContent: null,
  whatsNewContent: null,
  firstContentAuthor: null,
  vehicle: null,
  embeddedModules: null,
  headingText: null,
  isMobile: false,
  hasRouteYearParam: false,
  isUsed: false,
  isPreprod: false,
  isCoreOnSrp: false,
  showScorecard: true,
  showHighlights: false,
  showSummary: true,
  hasPreprodCta: false,
  hasCoreHighlights: false,
  toggleButton: null,
  hasTrimsLink: false,
  isCore6112Enabled: false,
  isCore6231Test: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const ScorecardEditorialReviewBody = connect(mapStateToProps)(
  connectToModel(ScorecardEditorialReviewBodyUI, {
    editorialReview: bindToPath(({ params }) => buildReviewPath(params), EditorialReviewModel),
    reviewAuthor: bindToPath(({ params }) => buildAuthorPath(params), EditorialReviewModel),
    reviewRatedBy: bindToPath(({ params }) => buildRatedByPath(params), EditorialReviewModel),
    firstContent: bindToPath(
      ({ vehicle, isCoreOnSrp }) => !isCoreOnSrp && buildPreProdContentPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel
    ),
    firstContentAuthor: bindToPath(
      ({ vehicle, isCoreOnSrp }) => !isCoreOnSrp && buildPreProdContentAuthorPath(getParamsFromVehicle(vehicle)),
      EditorialReviewModel
    ),
    whatsNewContent: bindToPath(
      ({ params, showHighlights }) => showHighlights && buildWhatsNewPath(params),
      VehicleDetailsModel
    ),
  })
);
