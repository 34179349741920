import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { EditorialRatingsEntities } from 'client/data/models/editorial-review';
import { getModelSectionMap } from 'site-modules/shared/utils/core-page/editorial-review';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { Link } from 'site-modules/shared/components/link/link';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { RatingBadge } from 'site-modules/shared/components/core-page/rating-badge/rating-badge';
import { RatingScorecardSection } from './rating-scorecard-section';
import { RatingScorecardEmbed } from './rating-scorecard-embed';

import './rating-scorecard.scss';

const INSERT_BEFORE = {
  FILMSTRIP: 'mpg',
  RESEARCH_LINKS: 'interior',
};

export function RatingScorecard({
  ratings,
  scale,
  modelName,
  edmundsTypeCategories,
  embeddedModules,
  showMobileSummary,
  isMobile,
}) {
  if (!get(ratings, 'overall.rating')) {
    return null;
  }

  // Embedded modules
  const pricingModule = get(embeddedModules, 'pricingModule');
  const filmstripModule = get(embeddedModules, 'filmstripModule');
  const researchLinksModule = get(embeddedModules, 'researchLinksModule');

  const modelSectionMap = getModelSectionMap(modelName, edmundsTypeCategories);

  const TOP_SECTIONS_MAP = [modelSectionMap.DRIVING, modelSectionMap.COMFORT];
  const BOTTOM_SECTIONS_MAP = [
    modelSectionMap.INTERIOR,
    modelSectionMap.TECHNOLOGY,
    modelSectionMap.UTILITY,
    modelSectionMap.MPG,
    modelSectionMap.VALUE,
    modelSectionMap.WILDCARD,
  ];

  return (
    <ScrollElement
      id="subnav-rating-scorecard"
      className="rating-scorecard mb-2"
      data-tracking-parent="edm-entry-review-scorecard"
    >
      <h3 className="heading-4 mt-2 mb-1_5">Edmunds Expert Rating</h3>
      <div className="bg-cool-gray-90 rounded-12 px-1 py-1 mb-1_5">
        <div className="font-weight-bold mb-1">
          Our Verdict
          <TooltipItem
            id="rating-scorecard-tooltip"
            className="top-0"
            iconClassName="text-primary-darker medium"
            innerClassName="p-1"
            tooltipClassName="px-10"
            item={{
              placement: 'bottom',
              text:
                'The Edmunds Vehicle Testing Team evaluates a fresh batch of vehicles every week, pairing objective ' +
                'assessments at our test track with real-world driving on city streets, freeways and winding roads. The ' +
                'data we gather results in our Expert Ratings. They’re based on 30-plus scores that cover every aspect ' +
                'of the automotive experience.',
            }}
          />
        </div>
        <div className="d-flex flex-column flex-md-row align-items-center px-1 py-1 bg-white rounded-8">
          <RatingBadge className="px-0_75" ratingScale={scale} editorialRating={ratings.overall.rating} />
          <div className="ml-md-0_5">
            {(!isMobile || showMobileSummary) && (
              <ContentFragment classes="review-section-fragment text-gray-darker size-16">
                {get(ratings, 'overall.text', '')}
              </ContentFragment>
            )}
            <em className="d-inline-block medium text-gray-darker text-center text-md-left mt-1_5">
              Rated for you by{' '}
              <Link
                to="/car-reviews/features/how-we-test-cars-and-trucks.html"
                className="test-team-link text-primary-darker"
                data-tracking-id="view_content"
                rel="nofollow"
              >
                America&apos;s best test team
              </Link>
              .
            </em>
          </div>
        </div>
      </div>

      {TOP_SECTIONS_MAP.map(({ name, question, key }, index) => {
        if (!get(ratings, `[${key}].rating`)) {
          return null;
        }

        const ratingQuestion = get(ratings, `[${key}].title`) || question;
        const ratingText = get(ratings, `[${key}].text`);
        const ratingEmbeds = get(ratings, `[${key}].embeds`);

        return (
          <RatingScorecardSection
            key={`scorecard-${key}`}
            className={classnames({ 'mt-1_5': index })}
            sectionKey={key}
            name={name}
            scale={scale}
            rating={ratings[key].rating}
            content={`<strong>${ratingQuestion}</strong> ${ratingText}`}
            embeds={ratingEmbeds}
            isMobile={isMobile}
          />
        );
      })}

      <RatingScorecardEmbed childrenClassName="mb-2" hasPricingModule isMobile={isMobile}>
        {pricingModule}
      </RatingScorecardEmbed>

      <div data-tracking-parent="edm-entry-review-scorecard2">
        {BOTTOM_SECTIONS_MAP.map(({ name, key, question }, index) => {
          const insertBefore = (
            <Fragment key={`before-${key}`}>
              {key === INSERT_BEFORE.FILMSTRIP && filmstripModule}
              {key === INSERT_BEFORE.RESEARCH_LINKS && researchLinksModule}
            </Fragment>
          );

          if (!get(ratings, `[${key}].rating`)) {
            return insertBefore;
          }

          const ratingQuestion = get(ratings, `[${key}].title`) || question;
          const ratingText = get(ratings, `[${key}].text`);
          const ratingEmbeds = get(ratings, `[${key}].embeds`);

          return (
            <Fragment key={`scorecard-${key}`}>
              {insertBefore}
              <RatingScorecardSection
                className={classnames({ 'mt-1_5': index || !pricingModule })}
                sectionKey={key}
                name={name}
                scale={scale}
                rating={ratings[key].rating}
                content={`${ratingQuestion ? `<strong>${ratingQuestion}</strong> ` : ''}${ratingText}`}
                embeds={ratingEmbeds}
                isMobile={isMobile}
              />
            </Fragment>
          );
        })}
      </div>
    </ScrollElement>
  );
}

RatingScorecard.propTypes = {
  ratings: EditorialRatingsEntities,
  scale: PropTypes.number,
  modelName: PropTypes.string,
  edmundsTypeCategories: PropTypes.objectOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  embeddedModules: PropTypes.shape({
    pricingModule: PropTypes.node,
    filmstripModule: PropTypes.node,
    researchLinksModule: PropTypes.node,
  }),
  showMobileSummary: PropTypes.bool,
  isMobile: PropTypes.bool,
};

RatingScorecard.defaultProps = {
  ratings: null,
  scale: 10,
  modelName: null,
  edmundsTypeCategories: null,
  embeddedModules: null,
  showMobileSummary: false,
  isMobile: false,
};
