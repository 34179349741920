import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { PricingAd } from 'site-modules/shared/components/native-ad/pricing-ad/pricing-ad';

export function RatingScorecardEmbed({ children, childrenClassName, isEmbed, hasPricingModule, isMobile }) {
  return (
    !!children && (
      <Fragment>
        {hasPricingModule && (
          <Experiment name="ads-9970" showDefault>
            <Recipe name="ctrl" isDefault />
            <Recipe name="chal">
              <PricingAd wrapperClass="mt-1_5 mb-0_5" mobile={isMobile} showHorizontalLayout showOnlySiteServed />
            </Recipe>
          </Experiment>
        )}
        {isEmbed && (
          <em className="d-inline-block mt-1 mb-2 medium text-gray-darker">
            <i className="icon-arrow-down8 small mr-0_25" aria-hidden /> Continue reading Edmunds Expert Rating below
          </em>
        )}
        <div className={childrenClassName}>{children}</div>
      </Fragment>
    )
  );
}

RatingScorecardEmbed.propTypes = {
  children: PropTypes.node,
  childrenClassName: PropTypes.string,
  isEmbed: PropTypes.bool,
  hasPricingModule: PropTypes.bool,
  isMobile: PropTypes.bool,
};

RatingScorecardEmbed.defaultProps = {
  children: null,
  childrenClassName: null,
  isEmbed: true,
  hasPricingModule: false,
  isMobile: false,
};
