import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { getFullImageUrl, getStaticPhotoUrl, isStaticSrc } from 'client/utils/image-helpers';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { EmbeddedYoutubeAsync } from 'site-modules/shared/components/video/embedded-youtube/embedded-youtube-async';

const EMBED_ID_REGEX = /<embed\s+data-embed-id="(.*?)"\s*\/>/g;

export function RatingScorecardSection({ name, sectionKey, rating, scale, content, embeds, className, isMobile }) {
  const imageSize = isMobile ? 300 : 717;

  return (
    <div className={classnames('rating-scorecard-section text-gray-darker', className)}>
      <div className="d-flex align-items-baseline mb-0_5">
        <h4 className="heading-5 text-primary-darker mb-0 mr-1">{name}</h4>
        {rating && (
          <div className="heading-3 text-primary-darker">
            {rating}
            <span className="medium font-weight-normal text-gray-darker ml-0_25">/{scale}</span>
          </div>
        )}
      </div>
      {content.split(EMBED_ID_REGEX).map((contentChunk, index) => {
        const key = `name-${index}`;
        const isEmbed = !!(index % 2);
        const embed = isEmbed && embeds && embeds.find(({ id }) => id === contentChunk);
        let thumbnailSrc;

        if (get(embed, 'thumbnailURL')) {
          thumbnailSrc = isStaticSrc(embed.thumbnailURL)
            ? getStaticPhotoUrl(embed.thumbnailURL, isMobile)
            : getFullImageUrl(embed.thumbnailURL, imageSize);
        }

        return (
          <Fragment key={key}>
            {isEmbed ? (
              <Fragment>
                {!!get(embed, 'videoId') && (
                  <div className="pl-md-1_5 mb-1" data-tracking-parent={`video-short-${sectionKey}`}>
                    <EmbeddedYoutubeAsync
                      videoId={embed.videoId}
                      startTime={+embed.startTime}
                      videoTitle={embed.videoTitle}
                      thumbnailSrc={thumbnailSrc}
                      creativeId={`video-${embed.videoId}`}
                      loaderClassName="m-0_25 pos-a top-0 left-0 right-0 bottom-0 border-0"
                      loaderTrackingId="vapor_video_start"
                      lazyLoadPreview
                      noPlayIcon
                    />
                  </div>
                )}
              </Fragment>
            ) : (
              <ContentFragment classes="review-section-fragment text-gray-darker size-16 pl-1_5">
                {contentChunk}
              </ContentFragment>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

RatingScorecardSection.propTypes = {
  scale: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sectionKey: PropTypes.string.isRequired,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.string,
  embeds: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

RatingScorecardSection.defaultProps = {
  rating: null,
  content: null,
  embeds: null,
  className: null,
  isMobile: false,
};
