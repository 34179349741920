import React, { startTransition, useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Input from 'reactstrap/lib/Input';
import ButtonDropdown from 'reactstrap/lib/ButtonDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import { fireTrackingZipChange } from 'site-modules/shared/utils/fire-tracking-zip-change';

import './zip.scss';

const ZIP_CODE_REGEX = /^[0-9]{5}$/;

export function Zip({
  zip,
  buttonText,
  buttonClassName,
  zipClassName,
  showDropdownIcon,
  buttonTrackingId,
  creativeId,
  updateBtnClasses,
  onUpdate,
}) {
  const [isOpen, setOpen] = useState(false);
  const [isValid, setValid] = useState(true);
  const zipInputRef = useRef();

  function validate() {
    const valid = ZIP_CODE_REGEX.test(zipInputRef.current.value);

    setValid(valid);
    return valid;
  }

  const onUpdateZipCode = useCallback(() => {
    if (!validate()) return;

    startTransition(() => {
      if (creativeId) fireTrackingZipChange(zip);
      onUpdate(zipInputRef.current.value);
    });
    setOpen(false);
  }, [creativeId, onUpdate, zip]);

  const onToggleOpen = useCallback(() => {
    setOpen(state => !state);
    setValid(true);
  }, []);

  return (
    <ButtonDropdown
      isOpen={isOpen}
      toggle={onToggleOpen}
      className="zip-container align-baseline"
      data-tracking-id="provide_zip_code"
      data-tracking-parent={creativeId}
    >
      <DropdownToggle
        className={classnames('d-inline-flex align-items-center m-0 p-0 zip', buttonClassName)}
        aria-haspopup
        aria-label={`ZIP Code: ${zip}`}
      >
        <span className={classnames('px-0_25', zipClassName)}>{buttonText || zip}</span>
        {showDropdownIcon && (
          <i
            aria-hidden
            className={classnames('brand-primary-darker size-10 py-0 my-0 pr-0_25 align-self-center', {
              'icon-arrow-up4': isOpen,
              'icon-arrow-down4': !isOpen,
            })}
          />
        )}
      </DropdownToggle>
      <DropdownMenu right className="zip-dropdown-shadow p-0_75">
        <form onSubmit={onUpdateZipCode} className="input-group">
          <Input
            className="zip-input size-16 text-gray-darker pr-0_5 pl-0_75 py-0_5"
            onChange={validate}
            type="number"
            placeholder={zip}
            defaultValue={zip}
            innerRef={zipInputRef}
            aria-label="Enter zip code"
            role="menuitem"
          />
          <InputGroupAddon addonType="append" className="p-0">
            <Button
              className={classnames('zip-submit-btn px-1_25 text-transform-none size-16', updateBtnClasses)}
              size="sm"
              color="primary-b"
              disabled={!isValid}
              type="submit"
              data-tracking-id={buttonTrackingId}
            >
              Update
            </Button>
          </InputGroupAddon>
        </form>
        {!isValid && <div className="heading-5 mt-0_25 text-danger">Zip code is invalid.</div>}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

Zip.propTypes = {
  zip: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  buttonClassName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  zipClassName: PropTypes.string,
  showDropdownIcon: PropTypes.bool,
  buttonTrackingId: PropTypes.string,
  creativeId: PropTypes.string,
  updateBtnClasses: PropTypes.string,
};

Zip.defaultProps = {
  zipClassName: '',
  buttonText: null,
  showDropdownIcon: true,
  buttonTrackingId: undefined,
  creativeId: undefined,
  updateBtnClasses: '',
};
