import { isHTMLValid } from './html-validator';

/**
 * Removes all html tags from string
 * @param {string} content
 * @returns {string}
 */
export function stripHTML(content) {
  if (typeof content !== 'string') {
    return '';
  }

  return content.replace(/<(?:.|\n)*?>/gm, '');
}

/**
 * Removes all html tags from string in case html is invalid
 * @param {string} content
 * @returns {string}
 */
export function stripInvalidHTML(content) {
  if (isHTMLValid(`<div>${content}</div>`)) {
    return content;
  }

  return stripHTML(content);
}

/**
 * Transforms '\n' (newline characters) into <br> tags
 *
 * @param {string} content
 * @returns {string}
 */
export function transformNewlines(content) {
  return content.replace(/\n/g, '<br>');
}

/**
 * We should not support any html in these comments.  We only expect
 * '\n' (newline characters) strings that should be replaced with '<br>'.
 *
 * @param {string} content
 * @returns {string}
 */
export function transformInventoryComments(content) {
  const htmlStrippedContent = stripHTML(content);
  return transformNewlines(htmlStrippedContent);
}
